@media print {
  .print-visible {
    display: block !important;
  }

  .print-visible-table {
    display: inline;
  }

  .print-hidden {
    display: none !important;
  }
}