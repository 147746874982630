input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    appearance:textfield ;
}

input[type=number]:nth-last-child(1) {
    margin: 0;
}

input[type=number]:last-child {
    margin-right: 0 !important;
    float: right !important; 
}

input[type=number]:nth-last-child(3) {
    margin-left: 40px !important;
}