.currentAndEffectiveLimit > .MuiStack-root.css-g3bxxk  {
  padding: 10px;
  margin: 0;
  height: 285px;
}

.currentAndEffectiveLimit > .MuiStack-root.css-g3bxxk > .MuiBox-root.css-nvdfhz {
  padding: 0;
  margin: 0;
}

.currentAndEffectiveLimit > 
.MuiStack-root.css-g3bxxk > 
.MuiBox-root.css-nvdfhz > 
.MuiDataGrid-root.MuiDataGrid-root--densityStandard.css-mabsq1 > 
.MuiDataGrid-main.css-opb0c2 >
.MuiDataGrid-columnHeaders.css-3ncfj0 >
.MuiDataGrid-columnHeadersInner.MuiDataGrid-columnHeadersInner--scrollable.css-10jhk8h > .css-k008qs > 
.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable > .MuiDataGrid-columnHeaderDraggableContainer > .MuiDataGrid-columnHeaderTitleContainer >
.MuiDataGrid-columnHeaderTitleContainerContent > 
.MuiDataGrid-columnHeaderTitle.css-cc8tf1
{
  font-size: 13px;
  padding: 0;
  margin: 0;
}
