@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: dotted 4px #cfd0d1;
  border-bottom-color: #D04D27;
  border-radius: 50%;
  content: '';
  height: 20px;
  width: 20px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
  display: inline-block;
}
