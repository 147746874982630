.dataGridCustom > 
.MuiStack-root.css-g3bxxk, 
.MuiBox-root.css-lc14rb {
    padding: 2px;
    height: 300px;
    margin-right: auto;
}

.dataGridCustom > .css-1p0uslz-MuiStack-root {
    height: 230px;
}

.dataGridCustom > .css-dlf8hq-MuiDataGrid-root .MuiDataGrid-virtualScrollerRenderZone {
    margin-top: 10px;
}
